<template>
  <div class="test">
    <img
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/huanxinxiang/test/card1.jpg"
    />
    <div class="form">
      <img
        class="one"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
      />
      <van-field
        v-model="phone"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
        @input="changePhone"
      />
      <img
        class="submit"
        @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/huanxinxiang/test/submit_lijidenglu.gif"
      />
      <div class="checked">
        <van-checkbox
          checked-color="#fb663e"
          v-model="checked"
          icon-size="18px"
        >
          我已阅读并同意<span @click="showPopup"
            >《个人信息授权与保护声明》</span
          >
        </van-checkbox>

        <div class="intro">资费25元/月</div>
      </div>
    </div>

    <img
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/huanxinxiang/test/card2_02.png"
    />
    <img
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/huanxinxiang/test/card3.jpg"
    />

    <div class="description">
      <div class="title">>>> 业务详情 <<<</div>
      <div class="cont">
        <div>1.活动对象：2G/3G/4G/5G手机用户</div>
        <div>
          本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。
        </div>
        <div>2.活动内容：</div>
        <div>
          订购随心享会员，25元/月，每30天可领取热门会员权益*2＋5元话费红包*1，到期前24小时内将自动扣费续订。例如张三在2023年3月15日10:56分订购了随心淘会员，那么在2023年3月15日10:56分~2023年4月13日10:55期间，张三可任选2款会员权益+5元话费红包。如张一在2023年4月12日10:55前未退订服务，那么在2023年4月12日10:55~2023年4月13日10:55期间将自动扣费下一个30天计费周期的费用。
        </div>
        <div>
          2.生失效方式：
          订购立即生效，当前计费周期费用（30天)一次性扣除下一个计费周期自动续订，退订需在到期前24小时。
        </div>
        <div>3.权益领取使用及有效期：</div>
        <div>会员权益每个计费周期（30天）限领1次，逾期作废。</div>
        <div>
          领取路径：登录支付宝APP,进入支付宝小程序【秋末会员商城】,点击【随心享会员】，进入后选择需要的权益领取；
        </div>
        <div>（1）视听会员权益如腾讯爱奇艺登录对应平台即可使用；</div>
        <div>（2）5元话费红包使用说明</div>
        <div>
          ①
          红包自用户领取起30天内有效，领取后发放到支付宝卡包，红包不可拆分，不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
        </div>
        <div>
          ②
          该红包可用场景为支付宝话费充值-话费常规充值/话费代扣充值(自动充),使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于5.01元时可抵扣使用(实际使用范围以红包可抵扣为准)。使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变；退回的红包若已过有效期则无法使用，且不予补发。
        </div>
        <div>
          ③
          支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与；同一用户活动期内仅能参加1次。同一支付宝账号、手机号、身份证、手机终端，符合以上任一条件均视为同一用户。
        </div>
        <div>
          ④
          为确保活动公平性，如用户出现不正当行为(如虚假交易、作弊、恶意套取现金、刷信等),银行有权撤销用户优惠资格，并有权收回用户获得的福利。
        </div>
        <div>4.退订方式</div>
        <div>
          退订用户需在会员权益到期前24小时联系取消，在下一个计费周期生效，且取消后3个月内不能再次订购；
        </div>
        <div>
          退订路径：打开支付宝-[我的]-[设置]-[支付设置]-[免密支付/自动扣款]，
          选择随心淘取消订阅微信[我的]-[服务]-[钱包]-[支付设置]-[自动续费]，选择随心淘取消订阅。
        </div>
        <div>5.如对活动有疑问或更多需求，请咨询在线客服或拨打4001189599</div>
        <div>【订购须知】</div>
        <div>
          1、本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉！
        </div>
        <div>
          2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，道防网络电信诈骗。
        </div>
      </div>
    </div>

    <div class="dialog" v-show="dialog">
      <div class="form">
        <img
          class="one"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
        />
        <van-field
          v-model="phone"
          type="tel"
          maxlength="11"
          placeholder="请输入您的手机号码"
          @input="changePhone"
        />
        <img
          class="submit"
          @click="onSubmit"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/huanxinxiang/test/submit_lijidenglu.gif"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { alipayCallbackPay } from "@/api/monthlyHotTopicsNTo2_HaiLuo.js";

export default {
  data() {
    return {
      phone: "",
      checked: false,
      dialog: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handlerscroll);
  },
  created() {
  },
  methods: {
    changePhone(value) {
      if (value.length == 11) {
        this.onSubmit();
      }
    },
    handlerscroll() {
      this.scrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.scrollTop >= 600) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
    showPopup() {
      this.$dialog
        .alert({
          messageAlign: "left",
          title: "个人信息授权与保护声明",
          message: `尊敬的客户：
您好!
根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
（1）事先获得客户的明确授权；
（2）根据有关的法律法规要求；
（3）按照相关司法机关和/或政府主管部门的要求；
（4）为维护社会公众的利益所必需且适当；
（5)为维护我公司或客户的合法权益所必需且适当。
`,
        })
        .then(() => {
          this.checked = true;
        });
    },

    async onSubmit() {
      // this.checked = true;
      // if (!this.checked) {
      //   this.$toast({
      //     duration: 8000,
      //     message: "请阅读并同意个人信息授权与保护声明",
      //   });
      // } else {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await alipayCallbackPay({
        phone_number: this.phone,
        item_name: "随心享会员",
        callback_name: "巨量",
        callback: location.search,
      });
      this.$toast.clear();
      if (res.code != 0) {
        this.$toast({
          duration: 8000,
          message: res.message,
        });
      } else {
        location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
          res.sign_str
        )}`;
      }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  background-color: #ffb450;
  padding-bottom: 450px;

  .card {
    width: 100%;
  }

  .form {
    position: relative;

    .one {
      width: 326px;
      position: absolute;
      z-index: 1;
      pointer-events: none;
      transform: translate(90px, -90px);
    }

    ::v-deep .van-cell {
      width: 674px;
      height: 120px;
      background-color: #ffffff;
      border-radius: 60px;
      margin: 90px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 56px;

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        &::-webkit-input-placeholder {
          font-size: 36px;
          font-weight: 40;
          color: #969696;
        }
      }
    }
    .submit {
      width: 730px;
      display: block;
      margin: 40px auto 0;
    }

    .checked {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 28px;
      font-weight: 400;
      padding: 10px 0 30px;

      ::v-deep .van-checkbox {
        width: auto;

        &__label {
          color: #fff !important;
        }
      }
    }
  }

  .description {
    background: #fdf9e3;
    margin: 40px;
    border-radius: 20px;
    font-size: 26px;
    line-height: 54px;
    padding: 10px;

    .title {
      text-align: center;
    }
  }

  .dialog {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #ffb450;
  }
}
</style>
